import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpParams } from '@angular/common/http';
import 'rxjs/add/operator/map'
import { BasicAuthInterceptor } from '../factory/interceptor';

@Injectable()
export class ProspectoService {

  constructor(private httpClient : HttpClient) {  
        
  }
  
  obtenerProspectoByCategoria(categoriaId: string) {
    return this.httpClient.get('http://adec-authorization.us-east-2.elasticbeanstalk.com/v1/ema-system/prospects?category_id='+categoriaId,{
      headers: {'token':sessionStorage.getItem('access_token')}
   }).map(data=>
    data);
  }

  guardarProspecto(prospecto) {

    return this.httpClient.post('http://adec-authorization.us-east-2.elasticbeanstalk.com/v1/ema-system/prospects', prospecto, {
      headers: new HttpHeaders({
           'Content-Type':  'application/x-www-form-urlencoded',
           'token':sessionStorage.getItem('access_token') , 'language_id': '1'
         })
    }).map(data=>
     data);

  }

  editarProspecto(prospecto) {

    return this.httpClient.put('http://adec-authorization.us-east-2.elasticbeanstalk.com/v1/ema-system/prospects', prospecto, {
      headers: new HttpHeaders({
           'Content-Type':  'application/x-www-form-urlencoded',
           'token':sessionStorage.getItem('access_token') , 'language_id': '1'
         })
    }).map(data=>
     data);
  }
  
  obtenerProspectoPorId(prospectoId) {
    return this.httpClient.get('http://adec-authorization.us-east-2.elasticbeanstalk.com/v1/ema-system/prospects?prospect_id='+prospectoId,{
      headers: {'token':sessionStorage.getItem('access_token')}
   }).map(data=>
    data);
  }

}