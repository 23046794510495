import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable()
export class PruebaService {

  valor: number;  

  //private userIdSource = new BehaviorSubject<number>(0);
  //currentUser = this.userIdSource.asObservable();

  constructor() { }
  
  setUser(userid: number) {
    this.valor =userid;
  }

  getUser(){
    let temp = this.valor;
   // this.clearData();
    return temp;
  }


}