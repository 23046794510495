import { Content } from '@angular/compiler/src/render3/r3_ast';
import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import 'rxjs/add/operator/map'

import { Observable } from 'rxjs/Observable';
import { switchMap } from 'rxjs/operators/switchMap';
import { finalize } from 'rxjs/operators/finalize';
import { timeout } from 'rxjs/operators/timeout';
import { catchError } from 'rxjs/operators/catchError';
import { _throw } from 'rxjs/observable/throw';
import { retry } from 'rxjs/operators/retry';
import { of } from 'rxjs/observable/of';

@Injectable()
export class applicationDataService {

  constructor(private httpClient : HttpClient) { }

  getServicesList(customer_id: number, customer_branch_id: number) {
      return this.httpClient
      .get('http://adec-authorization.us-east-2.elasticbeanstalk.com/v1/ema-system/services/services-lists').
      map(data => data);
  }
  getServicesTypesList(customer_id: number, customer_branch_id: number) {
    return this.httpClient
    .get('http://adec-authorization.us-east-2.elasticbeanstalk.com/v1/ema-system/services/services-types-list').
    map(data => data);
}
}
